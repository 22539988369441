import "./Softskills.css";

function Softskills() {
  return (
    <div className="softskills-page">
      <div className="softskills-card">
        <div className="softskills-cart-top-section">
          <p className="softskills-title">SOFT SKILLS</p>
        </div>
        <div className="softskills-cart-bottom-section">
          Being active as a volunteer while attending to classes (physically, in
          the good old days) was not always an easy job, and <span style={{fontWeight: "600"}}>time management</span> was
          a very important skill to have. I didn’t, but I acquired it through
          time, and I am very proud of it. At least I got, as everybody calls
          it, the <span style={{fontWeight: "600"}}>ability to work under pressure</span>, and I am not afraid of it,
          because I feel like it keeps me out of my comfort zone and pushes me
          to break my limits. Another very important skill is <span style={{fontWeight: "600"}}>creativity</span>. Even
          if I’m talking about content creation like images and videos for
          social media or internal purposes for MLSA, or about applications and
          web design ideas for SiSC, each situation helped me express it and led
          to an improvement. <span style={{fontWeight: "600"}}>Networking</span> is also needed in every context and
          working on projects with other people for university or for the NGOs
          taught me that <span style={{fontWeight: "600"}}>teamwork</span> and <span style={{fontWeight: "600"}}>communication</span> are valuable skills that can
          ruin everything if missing, alongside <span style={{fontWeight: "600"}}>positivity</span> and <span style={{fontWeight: "600"}}>humor</span>.
          Volunteering has also taught me about <span style={{fontWeight: "600"}}>responsibility</span> for the tasks
          that I took and about <span style={{fontWeight: "600"}}>adaptability</span> and <span style={{fontWeight: "600"}}>flexibility</span>, pretty important
          skills that you might need in working with a lot of people with
          different perspectives, developing a product for the needs of a bigger
          project.
        </div>
      </div>
    </div>
  );
}

export default Softskills;

import "./Hobbyuri.css";

function Hobbyuri() {
  return (
    <div className="hobbies-page">
      <div className="hobbies-card">
        <div className="hobbies-cart-top-section">
          <p className="hobbies-title">HOBBIES</p>
        </div>
        <div className="hobbies-cart-bottom-section">
          As I was saying in the about section, I have a passion for{" "}
          <span style={{fontWeight: "600"}}>innovations, technology and gadgets</span> and I enjoy being up to
          date with all the news about upcoming products in this area. Since
          this is about my hobbies and the previous ones are kind of related to
          my work, what I like most to do in my free time is{" "}
          <span style={{fontWeight: "600"}}>cook (and eat, obviously)</span>. Trying to imitate something I see or
          taste anywhere else will always be an accepted challenge. And because
          eating goes hand in hand with drinking, I wouldn’t say no to {" "}
          <span style={{fontWeight: "600"}}>trying to make a cocktail</span>. Besides that, even though I like{" "}
          <span style={{fontWeight: "600"}}>nature and long walks with some good music or my friends</span>, I’m
          more excited to go for <span style={{fontWeight: "600"}}>a drive with them</span>, without a chosen
          destination. Apart from my human friends, I love spending as much time
          as I can with <span style={{fontWeight: "600"}}>my two dogs</span> that are happy to see me all day
          everyday.
        </div>
      </div>
    </div>
  );
}

export default Hobbyuri;

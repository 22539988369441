import "./Hardskills.css";

function Hardskills() {
  return (
    <div className="hardskills-page">
      <div className="hardskills-card">
        <div className="hardskills-cart-top-section">
          <p className="hardskills-title">HARD SKILLS</p>
        </div>
        <div className="hardskills-cart-bottom-section">
          <ul>
            <li><span style={{fontWeight: "600"}}>Front-End</span> development with <span style={{fontWeight: "600"}}>JavaScript</span> frameworks like <span style={{fontWeight: "600"}}>React.js</span>, <span style={{fontWeight: "600"}}>Vue.js</span> or <span style={{fontWeight: "600"}}>Quasar</span></li>
            <li><span style={{fontWeight: "600"}}>Back-End</span> development with <span style={{fontWeight: "600"}}>Python</span> framework <span style={{fontWeight: "600"}}>Django</span>, with <span style={{fontWeight: "600"}}>JavaScript</span> framework <span style={{fontWeight: "600"}}>Express</span> (Node.js) or with Google’s <span style={{fontWeight: "600"}}>Firebase</span></li>
            <li>Using <span style={{fontWeight: "600"}}>SQL</span> and <span style={{fontWeight: "600"}}>PL/SQL</span> and working with relational databases (<span style={{fontWeight: "600"}}>MariaDB, SQLite, PostgreSQL</span>)</li>
            <li><span style={{fontWeight: "600"}}>Android</span> development using <span style={{fontWeight: "600"}}>Java</span></li>
            <li><span style={{fontWeight: "600"}}>OOP</span> in <span style={{fontWeight: "600"}}>Java</span> and <span style={{fontWeight: "600"}}>C++</span></li>
            <li><span style={{fontWeight: "600"}}>Data Structures</span> and their implementation using <span style={{fontWeight: "600"}}>C++</span></li>
            <li><span style={{fontWeight: "600"}}>Design Patterns</span> and their implementation using <span style={{fontWeight: "600"}}>Java</span></li>
            <li><span style={{fontWeight: "600"}}>JUnit</span> test-driven development</li>
            <li><span style={{fontWeight: "600"}}>Windows</span> software development with <span style={{fontWeight: "600"}}>C#</span></li>
            <li><span style={{fontWeight: "600"}}>Linux</span> knowledge as well as <span style={{fontWeight: "600"}}>Git</span> and <span style={{fontWeight: "600"}}>bash scripting</span></li>
            <li><span style={{fontWeight: "600"}}>Multimedia</span> files processing (image, video, audio) using <span style={{fontWeight: "600"}}>HTML (Canvas)</span> and <span style={{fontWeight: "600"}}>JavaScript</span></li>
            <li>Image, vectors and video editing working with <span style={{fontWeight: "600"}}>Adobe Creative Suite</span> (<span style={{fontWeight: "600"}}>Photoshop, Illustrator, PremierePro, AfterEffects</span>) or <span style={{fontWeight: "600"}}>DaVinci Resolve</span></li>
            <li><span style={{fontWeight: "600"}}>UX/UI</span> design using <span style={{fontWeight: "600"}}>Adobe XD</span> </li>

          </ul>
        </div>
      </div>
    </div>
  );
}

export default Hardskills;

import "./Despre.css";

function Despre() {
  return (
    <div className="about-page">
      <div className="about-card">
        <div className="about-card-top-section">
          <div>
            <img className="about-image" src="alex_albnegru.jpg" alt="avatar" />
          </div>
          <div className="about-title">
            <p className="about-title-row">Hi there, I'm</p>
            <p className="about-title-row">Mihai-Alexandru Zdrafcu!</p>
          </div>
        </div>
        <div className="about-card-bottom-section">
          Long story short, my name is Alex, I’m 22 and I have a passion for
          innovations, technology and gadgets. I’m an Economic Informatics
          student graduate from The Bucharest University of Economic Studies. In
          the three years of study, I have gained information on how to
          understand and analyze the problems of organisation and management of
          an enterprise, knowlege about research and application of the new
          computer science technologies, and plenty of programming skills. Besides that, I
          was a volunteer for two organizations: Microsoft Learn Student
          Ambassadors Romania ASE, as a member of the Graphics Department, Video
          Division, and Sindicatul Studenților din Cibernetică (Cybernetics
          Students Union), as a member of the IT Department, Front-end Division,
          where I improved my hard skills and soft skills and I acquired some
          new ones. I can also google things really fast.
        </div>
      </div>
    </div>
  );
}

export default Despre;

import { ExternalLink } from "react-external-link";
import "./Proiecte.css";

function Proiecte() {
  return (
    <div className="projects-page">
      <div className="projects-card">
        <div className="projects-cart-top-section">
          <p className="projects-title">PROJECTS</p>
        </div>
        <div className="projects-cart-bottom-section">
          <div className="projects-project">
            <div className="projects-project-title">
              Restaurant Ordering App
            </div>
            <div
              className="projects-project-description"
              style={{ marginBottom: "21px" }}
            >
              A progressive <span style={{fontWeight: "600"}}>web application</span> implemented to make the
              process of placing orders in restaurants more efficient. It is
              based on three functions: the possibility to book a table in the
              restaurant with the verification of its availability, depending on
              the number of people and the time at which the reservation is
              made), the process of remote placing an order (with delivery or
              pick-up, methods that we are already used to), and the most
              important of them, the process of placing a table service order.
              For the last one, the users choose their desired products using
              their personal devices, after scanning a QR code available on each
              table. The first order will open a bill with the possibility of
              ordering additional products with the same code, finally paying it
              through the same system. In addition, the application includes an
              authentication process, used for regular customers to save their
              orders, addresses or other required informations, and for
              administrators for being identified, gaining the privilege of
              managing orders. The front end was created using{" "}
              <span style={{fontWeight: "600"}}>Quasar framework</span>, with <span style={{fontWeight: "600"}}>Vue 3 Composition API</span> and{" "}
              <span style={{fontWeight: "600"}}>TypeScript</span>, <span style={{fontWeight: "600"}}>Django REST framework</span> being the
              technology used to achieve a powerful <span style={{fontWeight: "600"}}>Web API</span>. This is{" "}
              <span style={{fontWeight: "600"}}>my most important and complex project</span>, of which I am very
              proud, even though it belongs to the university I graduated, being{" "}
              <span style={{fontWeight: "600"}}>a part of my Bachelor’s degree</span>.
            </div>
          </div>

          <div className="projects-project">
            <div className="projects-project-title">ErrNote+</div>
            <div className="projects-project-description">
              This is a <span style={{fontWeight: "600"}}>web application</span> that allows students to take
              notes during courses/labs, organising them by classes they attend
              and individual study activities. Students are able to manage their
              notes by logging in with their institutional account, having lots
              of features available: view, add, edit or delete notes, set lables
              and add attachments. The app’s front end was is with the component
              base framework <span style={{fontWeight: "600"}}>React.js</span> with the Single Page Application
              architecture, its back end has a REST interface implemented in{" "}
              <span style={{fontWeight: "600"}}>Node.js using Express</span>, and the information is stored in a{" "}
              <span style={{fontWeight: "600"}}>MySQL database</span>, accessed through <span style={{fontWeight: "600"}}>Sequelize ORM</span>.
            </div>
            <div className="projects-project-repository">
              <ExternalLink
                className="link"
                href="https://github.com/alexzdrafcu/ErrNote"
                style={{ marginLeft: "5px" }}
              >
                [repository]
              </ExternalLink>
            </div>
          </div>

          <div className="projects-project">
            <div className="projects-project-title">Cockapp</div>
            <div className="projects-project-description">
              Because I love to cook, making cocktails became an activity that
              me and my girlfriend kind of enjoy from time to time. Since
              finding the best recipe can be quite difficult, we needed a place
              to save some of those gems. And this app was that place. And it’s
              called Cockapp (don’t judge, it’s personal). Besides that, we
              wanted to share our cocktails with our friends, and that was the
              most convenient solution. The app’s front end is built with{" "}
              <span style={{fontWeight: "600"}}>Quasar </span>
              using <span style={{fontWeight: "600"}}>Vue 3 Composition API</span> (deployed on Netlify), its back
              end is implemented in <span style={{fontWeight: "600"}}>Django REST framework</span> and the
              information is stored in a <span style={{fontWeight: "600"}}>PostgreSQL</span> database, using{" "}
              <span style={{fontWeight: "600"}}>Cloudinary</span> plugin as a media storage (deployed on Heroku).
            </div>
            <div className="projects-project-repository">
              <ExternalLink className="link" href="https://cockapp.netlify.app">
                [website]
              </ExternalLink>
              <ExternalLink
                className="link"
                href="https://cockapp.netlify.app"
                style={{ marginLeft: "5px" }}
              >
                [repository]
              </ExternalLink>
            </div>
            <div className="projects-project-repository"></div>
          </div>

          <div className="projects-project">
            <div className="projects-project-title">Coin Collector</div>
            <div className="projects-project-description">
              This project was a required assessment for the university’s{" "}
              <span style={{fontWeight: "600"}}>Mobile Devices and Applications course</span>, with a given
              subject. It’s an <span style={{fontWeight: "600"}}>Android application</span> used by coin
              collectors to keep track of their treasures, implemented with{" "}
              <span style={{fontWeight: "600"}}>Java</span>. Because every user has a different collection of
              coins, the app has an authentication feature and a storage for
              everyone’s information, using <span style={{fontWeight: "600"}}>Google’s Firebase</span>. Because of
              that, users can add, edit and remove their coins, or change their
              password. The application also has a{" "}
              <span style={{fontWeight: "600"}}>Room local database (SQLite)</span> where it’s stored a default
              collection of coins. Moreover, Coin Collector contains a fragment
              where are listed some “trending coins”, from a{" "}
              <span style={{fontWeight: "600"}}>JSON file stored on a server</span>
              at a specific address.
            </div>
            <div className="projects-project-repository">
              <ExternalLink
                className="link"
                href="/CoinCollectorDocumentation.pdf"
                target="_blanc"
                download
              >
                [PDF documentation]
              </ExternalLink>
              <ExternalLink
                className="link"
                href="https://github.com/alexzdrafcu/CoinCollector"
                style={{ marginLeft: "5px" }}
              >
                [repository]{" "}
              </ExternalLink>
            </div>
          </div>

          <div className="projects-project">
            <div className="projects-project-title">Multimedia editors</div>
            <div className="projects-project-description">
              Multimedia was a course where we learned how to{" "}
              <span style={{fontWeight: "600"}}>
                manage and edit images, videos or audio files using HTML and
                Javascript
              </span>
              . The following repository contains three small projects, one for
              each type of multimedia file. The image editor using <span style={{fontWeight: "600"}}>canvas</span>{" "}
              is built to be a <span style={{fontWeight: "600"}}>progressive web application</span>, and the video
              editor is built to be a <span style={{fontWeight: "600"}}>desktop GUI application</span> using{" "}
              <span style={{fontWeight: "600"}}>Electron</span>.
            </div>
            <div className="projects-project-repository">
              {" "}
              <ExternalLink
                className="link"
                href="https://github.com/alexzdrafcu/Multimedia"
              >
                [repository]
              </ExternalLink>
            </div>
          </div>

          <div className="projects-project">
            <div className="projects-project-title">Avigeo website</div>
            <div className="projects-project-description">
              This was <span style={{fontWeight: "600"}}>the first real website that I worked on</span> a few
              years ago with four of my friends and I feel like it really
              deserves to be here, being the starting point for me as a
              developer. It’s built using <span style={{fontWeight: "600"}}>HTML, SCSS and plain JavaScript</span>{" "}
              and serves as a presentation site for <span style={{fontWeight: "600"}}>Avigeo</span>.
            </div>
            <div className="projects-project-repository">
              {" "}
              <ExternalLink className="link" href="http://www.avigeo.ro">
                [website]
              </ExternalLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Proiecte;

import "./LimbiStraine.css";

function Limbistraine() {
  return (
    <div className="foreign-page">
      <div className="foreign-card">
        <div className="foreign-cart-top-section">
          <p className="foreign-title">LANGUAGES</p>
        </div>
        <div className="foreign-cart-bottom-section">
          <div className="foreign-language">
            <div className="foreign-language-title">Romanian</div>
            <div className="foreign-language-level">native</div>
          </div>
          <div className="language-progress-container">
            <div
              className="language-progress-content"
              style={{ width: "100%" }}
            ></div>
          </div>

          <div className="foreign-language">
            <div className="foreign-language-title">English</div>
            <div className="foreign-language-level">proficient</div>
          </div>
          <div className="language-progress-container">
            <div
              className="language-progress-content"
              style={{ width: "80%" }}
            ></div>
          </div>

          <div className="foreign-language">
            <div className="foreign-language-title">French</div>
            <div className="foreign-language-level">beginner</div>
          </div>
          <div className="language-progress-container">
            <div
              className="language-progress-content"
              style={{ width: "30%" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Limbistraine;

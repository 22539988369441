import { ExternalLink } from "react-external-link";

import "./Voluntariat.css";

function Voluntariat() {
  return (
    <div className="volunteering-page">
      <div className="volunteering-card">
        <div className="volunteering-cart-top-section">
          <p className="volunteering-title">Sindicatul Studenților</p>
          <p className="volunteering-title">din Cibernetică</p>
          <p className="education-subtitle">
            Front-end Division, IT Department (2019-2021)
          </p>
        </div>
        <div className="volunteering-cart-bottom-section">
          <span style={{ fontWeight: "600" }}>
            The Union of Cybernetics Students (SiSC){" "}
          </span>{" "}
          is a NGO that supports the students of the{" "}
          <span style={{ fontWeight: "600" }}>
            Faculty of Cybernetics, Statistics and Economic Informatics
          </span>{" "}
          and annually carries out projects dedicated to both professionally and
          personally development of young people. I am a member of the{" "}
          <span style={{ fontWeight: "600" }}>Front-end Division</span> in the{" "}
          <span style={{ fontWeight: "600" }}>IT Departament</span>, environment
          that helped me improve and gain a lot of skills while having the
          student life that everybody deserves. During my first year (as a
          junior), I had to attend to multiple trainings, like{" "}
          <span style={{ fontWeight: "600" }}>UX/UI Design</span>,
          <span style={{ fontWeight: "600" }}> HTML</span>,{" "}
          <span style={{ fontWeight: "600" }}>CSS</span> and{" "}
          <span style={{ fontWeight: "600" }}>JavaScript</span>, which created a
          more solid foundation of my web development knowledge. The first
          project I took part of (and the one I love the most) was{" "}
          <span style={{ fontWeight: "600" }}>Tap That Job 2020</span>. It was
          created for second year students to equate the mandatory practice
          stage, or for those interested in the atmosphere of the professional
          circumstances, by attending to a series of workshops held by several
          companies, after a selection process. As a part of the team, I had the
          opportunity to work for that project’s version of the website (
          <ExternalLink
            className="link"
            href="https://ttj.sisc.ro/"
            style={{ fontWeight: "700", fontStyle: "italic" }}
          >
            ttj.sisc.ro
          </ExternalLink>
          ), to contribute to the organization of the Open Day, to be a
          representative of the project through faculty, and to be an
          interviewer for the people who applied. I was also a participant in
          the project and I was chosen to take part in a series of courses
          taught by <span style={{ fontWeight: "600" }}>Ernst & Young</span>,
          through which I became familiar with the term{" "}
          <span style={{ fontWeight: "600" }}>
            RPA (Robotic process automation)
          </span>
          . It was an amazing experience which I repeated this year, as a helper
          for the new members, being a part of the know-how transmission
          process. The presentation website of the project was built using{" "}
          <span style={{ fontWeight: "600" }}>HTML</span>,{" "}
          <span style={{ fontWeight: "600" }}>CSS</span>,{" "}
          <span style={{ fontWeight: "600" }}>JavaScript</span> and{" "}
          <span style={{ fontWeight: "600" }}>Bootstrap</span>, and the{" "}
          <span style={{ fontWeight: "600" }}>TTJ App</span>
          (participants’ dashboard) was built using{" "}
          <span style={{ fontWeight: "600" }}>Vue.js</span>. Another big project
          of which I was part of the team was the last year’s recruitment
          program, <span style={{ fontWeight: "600" }}>SiSC Promo 2020</span>,
          when we developed a platform for the applicants and their management
          using <span style={{ fontWeight: "600" }}>Quasar</span>, that was
          migrated to <span style={{ fontWeight: "600" }}>React.js</span> this
          year (
          <ExternalLink
            className="link"
            href="https://siscovery.sisc.ro/"
            style={{ fontWeight: "700", fontStyle: "italic" }}
          >
            siscovery.sisc.ro
          </ExternalLink>
          ). <span style={{ fontWeight: "600" }}>SiSC Tutoring</span> (
          <ExternalLink
            className="link"
            href="https://tutoring.sisc.ro/"
            style={{ fontWeight: "700", fontStyle: "italic" }}
          >
            tutoring.sisc.ro
          </ExternalLink>
          ) and <span style={{ fontWeight: "600" }}>SiSCShop</span> (internal)
          are two smaller projects to which I also contributed over the past
          year.
        </div>
      </div>

      <div className="volunteering-card">
        <div className="volunteering-cart-top-section">
          <p className="volunteering-title">Microsoft Learn</p>
          <p className="volunteering-title">Student Ambassadors</p>
          <p className="education-subtitle">
            Video Division, Graphics Department (2018-2019)
          </p>
        </div>
        <div className="volunteering-cart-bottom-section">
          <span style={{ fontWeight: "600" }}>
            Microsoft Learn Student Ambassadors
          </span>{" "}
          (<span style={{ fontWeight: "600" }}>MLSA</span>, former{" "}
          <span style={{ fontWeight: "600" }}>Microsoft Student Partner</span>)
          is a method by which{" "}
          <span style={{ fontWeight: "600" }}>Microsoft</span> provides students
          the access to a bunch of valuable resources and offers them the
          opportunity to be part of a team. The main goal of the NGO was to
          promote technology among students, by organizing different events. As
          a member of the{" "}
          <span style={{ fontWeight: "600" }}>Video Division</span>, I was in
          charge of <span style={{ fontWeight: "600" }}>taking photos</span> and{" "}
          <span style={{ fontWeight: "600" }}>recording videos</span> on every
          occasion. Besides that, I had to create{" "}
          <span style={{ fontWeight: "600" }}>content</span> for{" "}
          <span style={{ fontWeight: "600" }}>social networks</span> or for
          internal use, like{" "}
          <span style={{ fontWeight: "600" }}>GIFs, short videos</span> or{" "}
          <span style={{ fontWeight: "600" }}>aftermovies</span>. Being also a
          part of the  <span style={{ fontWeight: "600" }}>Graphics Department</span>, it was everyone’s job to come up with
          new ideas and to give feedback whenever it was needed.  <span style={{ fontWeight: "600" }}>“A future with
          Microsoft”</span> was the first event that I was there for, through which
          some Microsoft representatives present students their employment
          opportunities. Another series of events of this type was  <span style={{ fontWeight: "600" }}>Meet the
          company</span>, where I had the chance to learn a few things about  <span style={{ fontWeight: "600" }}>NTT Data</span>
          {" "}and <span style={{ fontWeight: "600" }}>Axes Software</span>. One of the biggest projects of the organization was
          <span style={{ fontWeight: "600" }}>MS Week</span>, in collaboration with  <span style={{ fontWeight: "600" }}>MLSA UPB</span>, where we hosted five events
          (workshops or presentations) in consecutive days. Among the discussed
          topics were  <span style={{ fontWeight: "600" }}>Azure Functions, Xamarin, Machine Learning Studio with
          Artificial Intelligence</span> and  <span style={{ fontWeight: "600" }}>Quantum Computers</span>. A cool experience at
          the end of that week was trying  <span style={{ fontWeight: "600" }}>Microsoft’s HoloLens 2</span>, learning a lot
          about mixed reality. Of all the events I took part of,  <span style={{ fontWeight: "600" }}>“How to become
          an open source entrepreneur”</span> was  <span style={{ fontWeight: "600" }}>the most important</span> for me, because I
          met and spoke to  <span style={{ fontWeight: "600" }}>Michael Widenius</span>, the creator and original developer
          of MySQL and the founder of MariaDB Corporation. He gave us finnish
          liquorice liqueur.
        </div>
      </div>
    </div>
  );
}

export default Voluntariat;

import "./Certificari.css";

function Certificari() {
  return (
    <div className="certifications-page">
      <div className="certifications-card">
        <div className="certifications-cart-top-section">
          <p className="certifications-title">CERTIFICATIONS</p>
        </div>
        <div className="certifications-cart-bottom-section">
          <div className="certifications-certificate">
            <div className="certifications-certificate-titles">
              <p className="certifications-certificate-title">RPA Implementation Fundamentals </p>
            </div>
            <div className="certifications-certificate-level">2020</div>
          </div>
          <div className="certifications-certificate-issuer">
            UiPath Academy
          </div>

          <div className="certifications-certificate">
            <div className="certifications-certificate-titles">
              <p className="certifications-certificate-title">RPA Business Analyst Fundamentals</p>
            </div>
            <div className="certifications-certificate-level">2020</div>
          </div>
          <div className="certifications-certificate-issuer">
            UiPath Academy
          </div>

          <div className="certifications-certificate">
            <div className="certifications-certificate-titles">
              <p className="certifications-certificate-title">RPA Starter</p>
            </div>
            <div className="certifications-certificate-level">2020</div>
          </div>
          <div className="certifications-certificate-issuer">
            UiPath Academy
          </div>

          <div className="certifications-certificate">
            <div className="certifications-certificate-titles">
            <p className="certifications-certificate-title">Fundamentals of Digital Marketing</p>
            </div>
            <div className="certifications-certificate-level">2019</div>
          </div>
          <div className="certifications-certificate-issuer">
            Google Digital Garage
          </div>

          <div className="certifications-certificate">
            <div className="certifications-certificate-titles">
            <p className="certifications-certificate-title">Student Ambassador</p>
            </div>
            <div className="certifications-certificate-level">2019</div>
          </div>
          <div className="certifications-certificate-issuer">
            Microsoft Learn Student Ambassadors
          </div>

          <div className="certifications-certificate">
            <div className="certifications-certificate-titles">
            <p className="certifications-certificate-title">ECDL Profile Standard</p>
            </div>
            <div className="certifications-certificate-level">2018</div>
          </div>
          <div className="certifications-certificate-issuer">ECDL Romania</div>
        </div>
      </div>
    </div>
  );
}

export default Certificari;

import { ExternalLink } from "react-external-link";
import "./Contact.css";


function Contact() {
  return (
    <div className="contact-page">
      <div className="contact-card">
        <div className="contact-cart-top-section">
          <p className="contact-title">CONTACT</p>
        </div>
        <div className="contact-cart-bottom-section">
          <div> <i>phone:</i>
          <ExternalLink
           className="link"
           href="tel: +40721920097">
            <div className="contact-info">+40 721 920 097</div>
            </ExternalLink></div>
          <div> <i>email:</i>
          <ExternalLink
           className="link"
           href="mailto: alexzdrafcu@gmail.com">
          <div className="contact-info">alexzdrafcu@gmail.com</div>
          </ExternalLink></div>
          <div> <i>github:</i>
          <ExternalLink
           className="link"
           href="https://github.com/alexzdrafcu">
          <div className="contact-info">alexzdrafcu</div>
          </ExternalLink>
          </div>

          <div> <i>linkedin:</i>
          <ExternalLink
           className="link"
           href="https://www.linkedin.com/in/alexzdrafcu/">
          <div className="contact-info">alexzdrafcu</div>
          </ExternalLink></div>

        </div>
      </div>
    </div>
  );
}

export default Contact;

import { React, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ExternalLink } from "react-external-link";
import "./Navbar.css";
import "../App.css";

function Navbar() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isMenuOpen, setIsMenuOpen] = useState(splitLocation[1]===""?true:false);
  const [title, setTitle] = useState("");

  let toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  let linkClick = () => {
    setIsMenuOpen(false);
    setTitle(title);
  };
  let resetTitle = (splitLocation) => {
    switch (splitLocation[1]) {
      case "about":
        return "about";
      case "education":
        return "education";
      case "volunteering":
        return "volunteering";
      case "experience":
        return "professional experience";
      case "foreignlanguages":
        return "foreign languages";
      case "certifications":
        return "certifications";
      case "hardskills":
        return "hard skils";
      case "projects":
        return "projects";
      case "softskills":
        return "soft skills";
      case "hobbies":
        return "hobbies";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="topbar">
        <div
          className={isMenuOpen ? "hamburger open" : "hamburger"}
          onClick={toggleMenu}
        >
          <div></div>
        </div>
        <div className="topbar-title">{resetTitle(splitLocation)}</div>
      </div>
      <div
        className={
          (isMenuOpen ? "" : "close ") +
          (splitLocation[1] === "" ? "navbar" : "navbar-active ")
        }
      >
        <Link
          className="link"
          to="/about"
          onClick={() => {
            linkClick("about");
          }}
        >
          <div
            className={
              splitLocation[1] === "about"
                ? "navbar-row navbar-row-large active"
                : "navbar-row navbar-row-large"
            }
          >
            <span> Alex Zdrafcu</span>
          </div>
        </Link>

        <Link
          className="link"
          to="/education"
          onClick={() => {
            linkClick("education");
          }}
        >
          <div
            className={
              splitLocation[1] === "education"
                ? "navbar-row navbar-row-medium educatie active"
                : "navbar-row navbar-row-medium educatie"
            }
          >
            <span> education </span>
          </div>
        </Link>

        <Link
          className="link"
          to="/volunteering"
          onClick={() => {
            linkClick("volunteering");
          }}
        >
          <div
            className={
              splitLocation[1] === "volunteering"
                ? "navbar-row navbar-row-medium voluntariat active"
                : "navbar-row navbar-row-medium voluntariat"
            }
          >
            <span> volunteering </span>
          </div>
        </Link>

        <Link
          className="link"
          to="/experience"
          onClick={() => {
            linkClick("professional experience");
          }}
        >
          <div
            className={
              splitLocation[1] === "experience"
                ? "navbar-row navbar-row-medium experienta active"
                : "navbar-row navbar-row-medium experienta"
            }
          >
            <span> professional experience</span>
          </div>
        </Link>

        <div className="navbar-row navbar-row-small">
          <Link
            className="link link-small link-small-left"
            to="/foreignlanguages"
            onClick={() => {
              linkClick("foreign languages");
            }}
          >
            <div
              className={
                splitLocation[1] === "foreignlanguages"
                  ? "navbar-row navbar-row-small-left active"
                  : "navbar-row navbar-row-small-left"
              }
            >
              foreign languages
            </div>
          </Link>

          <Link
            className="link link-small link-small-right"
            to="/certifications"
            onClick={() => {
              linkClick("certifications");
            }}
          >
            <div
              className={
                splitLocation[1] === "certifications"
                  ? "navbar-row navbar-row-small-right active"
                  : "navbar-row navbar-row-small-right"
              }
            >
              certifications
            </div>
          </Link>
        </div>

        <div className="navbar-row navbar-row-small">
          <Link
            className="link link-small link-small-left"
            to="/hardskills"
            onClick={() => {
              linkClick("hard skills");
            }}
          >
            <div
              className={
                splitLocation[1] === "hardskills"
                  ? "navbar-row navbar-row-small-left active"
                  : "navbar-row navbar-row-small-left"
              }
            >
              hard skills
            </div>
          </Link>

          <Link
            className="link link-small link-small-right"
            to="/projects"
            onClick={() => {
              linkClick("projects");
            }}
          >
            <div
              className={
                splitLocation[1] === "projects"
                  ? "navbar-row navbar-row-small-right active"
                  : "navbar-row navbar-row-small-right"
              }
            >
              projects
            </div>
          </Link>
        </div>
        <div className="navbar-row navbar-row-small">
          <Link
            className="link link-small link-small-left"
            to="/softskills"
            onClick={() => {
              linkClick("soft skills");
            }}
          >
            <div
              className={
                splitLocation[1] === "softskills"
                  ? "navbar-row navbar-row-small-left active"
                  : "navbar-row navbar-row-small-left"
              }
            >
              soft skills
            </div>
          </Link>

          <Link
            className="link link-small link-small-right"
            to="/hobbies"
            onClick={() => {
              linkClick("hobbies");
            }}
          >
            <div
              className={
                splitLocation[1] === "hobbies"
                  ? "navbar-row navbar-row-small-right active"
                  : "navbar-row navbar-row-small-right"
              }
            >
              hobbies
            </div>
          </Link>
        </div>
        <div className="navbar-row navbar-row-small">
          <Link
            className="link link-small link-small-left"
            to="/contact"
            onClick={() => {
              linkClick("contact");
            }}
          >
            <div
              className={
                splitLocation[1] === "contact"
                  ? "navbar-row navbar-row-small-left active"
                  : "navbar-row navbar-row-small-left"
              }
            >
              contact
            </div>
          </Link>
          <ExternalLink
            className="link link-small link-small-right"
            href="https://www.linkedin.com/in/alexzdrafcu/"
          >
            <div className="navbar-row navbar-row-small-right">linkedin</div>
          </ExternalLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

// className={
//     window.location.pathname == "/despre"
//       ? "navbar-row navbar-row-large active"
//       : "navbar-row navbar-row-large"
//   }

import "./Educatie.css";

function Educatie() {
  return (
    <div className="education-page">
      <div className="education-card">
        <div className="education-card-top-section">
          <p className="education-title">Economic Informatics</p>
          <p className="education-subtitle">
            The Bucharest University of Economic Studies
          </p>
          <p className="education-year">2018-2021</p>
        </div>
        <div className="education-card-bottom-section">
          This year I graduated <span style={{fontWeight: "600"}}>Economic Informatics</span> from <span style={{fontWeight: "600"}}>The Bucharest
          University of Economic Studies</span>. Combining information science and
          economics into a new field, <span style={{fontWeight: "600"}}>The Faculty of Cibernetics, Statistics and
          Economic Informatics</span> aims to teach its students the abilities to apply
          adequate mathematical, econometric, statistical and information models
          to gain knowledge of, analyze and elaborate forecasts on economic
          phenomena and processes, to use and develop software to provide useful
          information to decision-making factors or various categories of
          end-users at micro and macro-economic level and to elaborate
          feasibility and diagnostic studies, to provide IT solutions for
          competitive risk management. Because of the <span style={{fontWeight: "600"}}>Economic Informatics
          profile</span>, I had the opportunity to deepen my knowledge understanding
          and application of concepts, methods, techniques specific to
          information systems and programming languages:
        </div>
        <div className="education-card-courses-section">
          <ul>
            <li>Mathematical and logical operations using <span style={{fontWeight: "600"}}>Assembler</span> programming language</li>
            <li>Understanding operating systems and <span style={{fontWeight: "600"}}>shell scripting using Linux server</span></li>
            <li><span style={{fontWeight: "600"}}>OOP</span> and <span style={{fontWeight: "600"}}>Data Structures</span> using <span style={{fontWeight: "600"}}>C/C++</span></li>
            <li><span style={{fontWeight: "600"}}>Windows Applications Programming</span> using <span style={{fontWeight: "600"}}>C#</span></li>
            <li><span style={{fontWeight: "600"}}>OOP</span>, <span style={{fontWeight: "600"}}>Design Patterns</span> (with <span style={{fontWeight: "600"}}>JUnit</span> test driven development), <span style={{fontWeight: "600"}}>Computer Networks</span> and <span style={{fontWeight: "600"}}>Android</span> applications development using <span style={{fontWeight: "600"}}>Java</span></li>
            <li><span style={{fontWeight: "600"}}>Database management systems</span> using <span style={{fontWeight: "600"}}>SQL</span> and <span style={{fontWeight: "600"}}>PL/SQL</span></li>
            <li><span style={{fontWeight: "600"}}>Genetic algorithms</span> and <span style={{fontWeight: "600"}}>data analysis</span> using <span style={{fontWeight: "600"}}>Python</span></li>
            <li><span style={{fontWeight: "600"}}>Multimedia</span> files manipulation using <span style={{fontWeight: "600"}}>HTML</span>, <span style={{fontWeight: "600"}}>CSS</span> and <span style={{fontWeight: "600"}}>JavaScript</span></li>
            <li><span style={{fontWeight: "600"}}>Web Technologies</span> and development using <span style={{fontWeight: "600"}}>React.js</span> and <span style={{fontWeight: "600"}}>Node.js</span></li>

          </ul>
        </div>
      </div>

      <div className="education-card">
        <div className="education-card-top-section">
          <p className="education-title">Mathematics and Informatics</p>
          <p className="education-subtitle">
            "Ienăchiță Văcărescu" National College
          </p>
          <p className="education-year">2014-2018</p>
        </div>
        <div className="education-card-bottom-section">
          Due to the mathematics-informatics profile of the high school I
          graduated, I obtained a{" "}
          <span style={{fontWeight: "600"}}>certificate of professional competencies</span> in "carrying out the
          management of websites, computer operating systems and providing data
          processing facilities, including support services" . Moreover, I
          participated in numerous <span style={{fontWeight: "600"}}>volunteer actions</span> within{" "}
          <span style={{fontWeight: "600"}}>Interact Târgoviște</span> and I got involved and enthusiastically
          supported the <span style={{fontWeight: "600"}}>BABEL Performing Arts Festival</span> (June 2-11, 2017),
          where I also was a <span style={{fontWeight: "600"}}>volunteer</span>.
        </div>
      </div>
    </div>
  );
}

export default Educatie;

import "./Experienta.css";

function Experienta() {
  return (
    <div className="experience-page">
      <div className="experience-card">
        <div className="experience-cart-top-section">
          <p className="experience-title">Ernst&Young</p>
          <p className="experience-subtitle">practice stage (2020)</p>
          {/* <p className="experience-year">2020</p> */}
        </div>
        <div className="experience-cart-bottom-section">
          Following a selection process, I was chosen to take part in a series
          of courses taught by <span style={{fontWeight: "600"}}>Ernst & Young</span>, through which I became
          familiar with the term <span style={{fontWeight: "600"}}>RPA (Robotic process automation)</span>, a
          fairly new technology that is constantly growing. Thus, I was given
          the opportunity to accumulate new information on how to carry out
          activities in a company, the work environment and the technologies
          that are used. The courses were divided into 3 parts:{" "}
          <span style={{fontWeight: "600"}}>Kick-off discussion</span> - a video call through which I met the
          team, I found out how the project was to be carried out and the
          structure of the courses, <span style={{fontWeight: "600"}}>UiPath Online Courses</span> – the
          introduction to RPA and deepening knowledge through 4 courses of the
          company UiPath, the provider of process automation software solutions
          (
          <span style={{fontWeight: "600"}}>
            RPA Starter, RPA Business Analyst Fundamentals, Introduction to RPA
            developer role and RPA Implementation Methodology
          </span>
          ) and <span style={{fontWeight: "600"}}>Debriefing calls</span> - a video call every day of the courses,
          through which I received more information about the company's activity
          and answers to my questions.
        </div>
      </div>
    </div>
  );
}

export default Experienta;

import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Certificari from "./Pages/Certificari";
import Contact from "./Pages/Contact";
import Despre from "./Pages/Despre";
import Educatie from "./Pages/Educatie";
import Experienta from "./Pages/Experienta";
import Hardskills from "./Pages/Hardskills";
import Hobbyuri from "./Pages/Hobbyuri";
import Limbistraine from "./Pages/Limbistraine";
import Proiecte from "./Pages/Proiecte"; 
import Softskills from "./Pages/Softskills";
import Voluntariat from "./Pages/Voluntariat";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar></Navbar>
        <div className="content">
          <Route path="/about" exact={true} component={Despre} />
          <Route path="/education" exact={true} component={Educatie} />
          <Route path="/volunteering" exact={true} component={Voluntariat} />
          <Route path="/experience" exact={true} component={Experienta} />
          <Route path="/foreignlanguages" exact={true} component={Limbistraine} />
          <Route path="/certifications" exact={true} component={Certificari} />
          <Route path="/hardskills" exact={true} component={Hardskills} />
          <Route path="/projects" exact={true} component={Proiecte} />
          <Route path="/softskills" exact={true} component={Softskills} />
          <Route path="/hobbies" exact={true} component={Hobbyuri} />
          <Route path="/contact" exact={true} component={Contact}/>
          {/* <Route path="/linkedin" exact={true} window.loca/> */}
        </div>
      </Router>
    </div>
  );
}

export default App;
